<template>
  <section>
    <div class="member-list" v-show="pageDetailHide" v-if="refreshStatus">
      <edp-header-search
        class="header-bg"
        :headerLeftWord="topTitle"
        :searchPlaceHolder="$t('member.searchPlaceHolder')"
        @search="searchFn"
      ></edp-header-search>
      <div class="topTips fac">
        <img src="@/src/assets/member/notice.png" alt="" class="tip" />
        <span class="f14 op5"> {{ $t("member.dataRange2") }}</span>
      </div>
      <div class="edp-common-content member-list-content pot">
        <div class="tab-wrap mb16">
          <div
            class="tab-item fjc"
            :class="{ active: tabIndex == 0 }"
            @click="onTab(0)"
          >
            {{
              type == 3 ? $t("member.SortLastReport") : $t("member.SortDateEmp")
            }}
          </div>
          <div
            class="tab-item fjc"
            :class="{ active: tabIndex == 1 }"
            @click="onTab(1)"
          >
            {{ $t("member.Sortdep") }}
          </div>
          <div
            class="tab-item fjc"
            :class="{ active: tabIndex == 2 }"
            @click="onTab(2)"
          >
            A-Z
          </div>
          <div class="split-line"></div>
          <div
            class="tab-item fjc"
            :class="{ active: sorted === true }"
            @click="switchSort"
          >
            <img src="@/src/assets/common/sort.png" alt="" class="icon-pic" />
          </div>
        </div>
        <div
          v-infinite-scroll="load"
          :infinite-scroll-delay="0"
          :infinite-scroll-distance="200"
          :infinite-scroll-disabled="disabled"
          class="member-block"
        >
          <template v-if="tabIndex == 0 || tabIndex == 2">
            <div class="member-wrap">
              <div
                class="member-item"
                v-for="(item, i) in pageList"
                :key="i"
                @click="onDetail(item.cdsid)"
              >
                <img :src="item.photo || nullImg" alt="" class="item-pic" />
                <div class="item-text">
                  <div class="text-one">
                    <span class="name-text txt-elps">{{
                      utils.formatLang(lang, item.cname, item.ename)
                    }}</span>
                    <em v-if="item.empGenerations" class="lable"
                      ><span>{{ item.empGenerations }}</span></em
                    >
                  </div>
                  <!-- <div class="text-two">
                  <span class="tag">{{ item.gender }}</span
                  ><span class="tag"
                    >{{ item.age }}{{ $t("member.yearsold") }}</span
                  ><span class="tag">{{ item.baseAddress }}</span
                  ><span class="tag">{{ item.educationBackground }}</span> -->
                  <!-- <span class="tag" v-if="item.childless">{{
                $t("member.Havechild")
              }}</span
              ><span class="tag" v-else>{{ $t("member.Childless") }}</span> -->
                  <!-- </div> -->
                  <div class="text-thr">
                    <div class="text-item txt-elps">
                      <em>{{ $t("member.Department") }}</em>
                      <span
                        ><span>{{
                          utils.formatLang(lang, item.dept, item.deptEname)
                        }}</span></span
                      >
                    </div>
                  </div>
                  <div class="text-thr" v-if="item.subdivision">
                    <div class="text-item">
                      <em>{{ $t("member.Sub-department") }}</em>
                      <span
                        ><span>{{ item.subdivision }}</span></span
                      >
                    </div>
                    <!-- <div class="text-item">
                    {{ $t("member.Job-position") }}
                    <span>{{ item.jobPosition }}</span>
                  </div> -->
                  </div>
                  <div class="text-thr">
                    <div class="text-item">
                      <em>{{ $t("member.Leader") }}</em>
                      <span class="txt-elps"
                        ><span>{{
                          utils.formatLang(
                            lang,
                            item.directLeaderChineseName,
                            item.directLeaderEnName
                          )
                        }}</span></span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p
              v-if="noMore && total > 0 && !loading"
              class="end-wrap tac f16 pt10"
            >
              <span class="line"></span>
              <span class="txt">{{ $t("member.end") }}</span
              ><span class="line"></span>
            </p>
          </template>
          <template v-if="tabIndex == 1">
            <div v-for="(item, i) in deptList" :key="i">
              <p class="fb f24 member-title">
                {{ utils.formatLang(lang, item.dept, item.deptEname) }}
              </p>
              <div class="member-wrap">
                <div
                  class="member-item"
                  v-for="(items, j) in item.list"
                  :key="j"
                  @click="onDetail(items.cdsid)"
                >
                  <img :src="items.photo || nullImg" alt="" class="item-pic" />
                  <div class="item-text">
                    <div class="text-one">
                      <span class="name-text txt-elps">{{
                        utils.formatLang(lang, items.cname, items.ename)
                      }}</span>
                      <em v-if="items.empGenerations" class="lable"
                        ><span>{{ items.empGenerations }}</span></em
                      >
                    </div>
                    <!-- <div class="text-two">
                    <span class="tag">{{ items.gender }}</span
                    ><span class="tag"
                      >{{ items.age }}{{ $t("member.yearsold") }}</span
                    ><span class="tag">{{ items.baseAddress }}</span
                    ><span class="tag">{{ items.educationBackground }}</span> -->
                    <!-- <span class="tag" v-if="item.childless">{{
                $t("member.Havechild")
              }}</span
              ><span class="tag" v-else>{{ $t("member.Childless") }}</span> -->
                    <!-- </div> -->
                    <div class="text-thr">
                      <div class="text-item txt-elps">
                        <em>{{ $t("member.Department") }}</em>
                        <span
                          ><span>{{
                            utils.formatLang(lang, items.dept, items.deptEname)
                          }}</span></span
                        >
                      </div>
                    </div>
                    <div class="text-thr" v-if="items.subdivision">
                      <div class="text-item txt-elps">
                        <em>{{ $t("member.Sub-department") }}</em>
                        <span
                          ><span>{{ items.subdivision }}</span></span
                        >
                      </div>
                      <!-- <div class="text-item">
                      {{ $t("member.Job-position") }}
                      <span>{{ items.jobPosition }}</span>
                    </div> -->
                    </div>
                    <div class="text-thr">
                      <div class="text-item txt-elps">
                        {{ $t("member.Leader") }}
                        <span
                          ><span>{{
                            utils.formatLang(
                              lang,
                              items.directLeaderChineseName,
                              items.directLeaderEnName
                            )
                          }}</span></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p
              v-if="noMore && total > 0 && !loading"
              class="end-wrap tac f16 pt10"
            >
              <span class="line"></span>
              <span class="txt">{{ $t("member.end") }}</span
              ><span class="line"></span>
            </p>
          </template>
          <div v-if="total === 0" class="user-empty tac">
            <img src="@/src/assets/member/user-empty.png" alt="" class="mb16" />
            <p class="f14 tac">{{ $t("member.NoResults") }}</p>
          </div>
        </div>
        <p v-if="loading" class="mb8 tac pt10">
          <van-loading
            type="spinner"
            size="24px"
            color="#464950"
            text-color="#464950"
            >{{ $t("member.loading") }}</van-loading
          >
        </p>
      </div>
    </div>

    <router-view v-if="!pageDetailHide" />
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      topTitle: null,
      type: null,
      tabIndex: 0,
      sorted: false,
      total: 0,
      totalPage: 0,
      query: {
        current: 1,
        size: 20,
        keywords: "",
        sortedKey: "TIME",
        sorted: "asc",
      },
      pageList: [],
      loading: false,
      lastDept: "",
      deptList: [{ dept: "", deptEname: "", list: [] }],
      nullImg: require("@/src/assets/common/default.png"),

      pageDetailHide: true,
      pageFirstVisit: true,
      refreshStatus: false,
    };
  },
  computed: {
    ...mapState({
      lang: (state) => state.login.lang,
    }),
    noMore() {
      return this.query.current >= this.totalPage;
    },
    disabled() {
      return this.loading || this.noMore;
    },
  },
  watch: {
    "$route.path"(val, oldVal) {
      let lastName = val.split("/"),
        lastNameLength = lastName.length,
        lastResult = lastName[lastNameLength - 1],
        lastResultfirst = lastName[1];

      let oldName = oldVal.split("/"),
        oldNameLength = oldName.length,
        oldNameFirst = oldName[1];

      if (
        lastResult == "memberDetail" ||
        lastResult == "talent" ||
        lastResult == "workshipRelation"
      ) {
        this.pageDetailHide = false;
      } else {
        this.pageDetailHide = true;

        if (this.pageFirstVisit) {
          this.pageFirstVisit = false;
          this.refreshStatus = true;

          this.initNew();
        }
      }

      if (
        (lastNameLength == 2 && oldNameLength == 2) ||
        (lastNameLength != oldNameLength && lastResultfirst != oldNameFirst)
      ) {
        window.location.reload();
      }
    },
  },
  mounted() {
    // detail pd
    let thisPath = this.$route.path;
    let lastName = thisPath.split("/"),
      lastNameLength = lastName.length,
      lastResult = lastName[lastNameLength - 1];

    if (
      lastResult == "memberDetail" ||
      lastResult == "talent" ||
      lastResult == "workshipRelation"
    ) {
      this.pageDetailHide = false;
    } else {
      this.pageDetailHide = true;

      if (this.pageFirstVisit) {
        this.pageFirstVisit = false;
        this.refreshStatus = true;

        this.initNew();
      }
    }
    // detail pd end
  },
  methods: {
    ...mapActions({
      empList: "emp/empList",
      talentList: "moment/getTalentList",
      anyUsers: "emp/anyUsers",
      fbList: "feedBack/fbUser",
      empWorkRelation: "emp/empWorkRelation",
    }),
    initNew() {
      if (this.$route.query.keyWord) {
        this.query.keywords = this.$route.query.keyWord;
      }
      if (this.$route.query.searchId) {
        this.query.keywords = this.$route.query.searchId;
      }
      this.type = this.$route.query.type;
      if (this.type == 0) {
        this.topTitle = this.$t("member.detailHeader");
      }
      if (this.type == 1) {
        this.topTitle = this.$t("moment.TalentGrowth");
      }
      // if (this.type == 2) {
      //   this.topTitle = this.$t("member.Annualreport");
      // }
      if (this.type == 3) {
        this.topTitle = this.$t("member.360Headers");
      }
      if (this.type == 4) {
        this.topTitle = this.$t("workshipRelation.workRelationshipGraph");
      }

      this.getList();
    },
    load() {
      this.query.current++;
      this.getList();
    },
    async getList(val) {
      if (val) {
        this.query.current = 1;
        this.pageList = [];
        this.deptList = [{ dept: "", deptEname: "", list: [] }];
      }
      this.loading = true;
      let params = { ...this.query };
      let res = {};
      if (this.type == 0) {
        res = await this.empList(params);
      }
      if (this.type == 1) {
        res = await this.talentList(params);
      }
      if (this.type == 2) {
        res = await this.anyUsers(params);
      }
      if (this.type == 3) {
        res = await this.fbList(params);
      }
      if (this.type == 4) {
        res = await this.empWorkRelation(params);
      }
      let datas = res.data.records;
      if (this.tabIndex === 1 && datas.length) {
        this.setDept(datas);
      } else {
        this.pageList = [...this.pageList, ...datas];
      }
      this.current = res.data.current;
      this.total = res.data.total;
      this.totalPage = parseInt(this.total / this.query.size);
      let num = this.total % this.query.size;
      if (num > 0) {
        this.totalPage += 1;
      }
      this.loading = false;
    },
    setDept(datas) {
      if (deptIndex === 0) {
        this.lastDept = datas[0].deptId;
      }
      let deptIndex = this.deptList.length - 1;

      let that = this;
      for (let i = 0; i < datas.length; i++) {
        const e = datas[i];
        if (e.deptId == that.lastDept) {
          that.deptList[deptIndex].dept = e.dept;
          that.deptList[deptIndex].deptEname = e.deptEname;
          that.deptList[deptIndex].list.push(e);
        } else {
          deptIndex++;
          that.lastDept = e.deptId;
          that.deptList.push({ dept: "", deptEname: "", list: [] });
          let arrs = datas.slice(i, datas.length);
          this.setDept(arrs);
          break;
        }
      }
    },
    searchFn(data) {
      this.query.keywords = data.searchName;
      this.getList(1);
    },
    onTab(val) {
      this.tabIndex = val;
      let text = "";
      switch (val) {
        case 0:
          text = "TIME";
          break;
        case 1:
          text = "DEPT";
          break;
        case 2:
          text = "NAME";
          break;
        default:
          text = "TIME";
          break;
      }
      this.query.sortedKey = text;
      this.getList(1);
    },
    switchSort() {
      this.sorted = !this.sorted;
      if (this.sorted === true) {
        this.query.sorted = "desc";
      } else {
        this.query.sorted = "asc";
      }
      this.getList(1);
    },
    onDetail(val) {
      let sercetCd = this.utils.encryption(val);

      let thisPath = this.$route.path;

      if (this.type == 0) {
        this.$router.push({
          path: thisPath + "/memberDetail",
          query: { id: sercetCd },
        });
      }
      if (this.type == 1) {
        this.$router.push({
          path: thisPath + "/talent",
          query: { id: sercetCd },
        });
      }
      /*if (this.type == 3) {
        this.$router.push({ name: "360feedback", query: { id: sercetCd } });
      }*/
      if (this.type == 4) {
        this.$router.push({
          path: thisPath + "/workshipRelation",
          query: { id: sercetCd },
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";
@media screen and (max-width: 1600px) {
  .member-list {
    overflow: hidden;
    .member-list-content {
      padding: 15px 0 20px;

      .tab-wrap {
        display: flex;
        align-items: center;
        padding-left: toPad(40);
        .tab-item {
          height: toPad(45);
          padding: 0 toPad(16);
          border-radius: toPad(6);
          margin-right: toPad(10);

          .icon-pic {
            width: toPad(16);
            height: toPad(16);
          }

          &.active {
            background: rgba(109, 182, 147, 0.17);
          }
        }
        .split-line {
          width: 1px;
          height: toPad(20);
          margin-right: toPad(10);
          background: rgba(0, 0, 0, 0.3);
        }
      }
      .member-block {
        overflow: auto;
        height: 71vh;
        padding: 0 toPad(40);

        .member-title {
          line-height: toPad(62);
          //margin: toPad(14) 0;
        }

        .member-wrap {
          display: flex;
          flex-wrap: wrap;
          //min-height: 80vh;
          .member-item {
            display: flex;
            width: toPad(408);
            height: toPad(134);
            padding: toPad(15);
            margin: toPad(10) toPad(6) 0 0;
            background: #fff;
            box-shadow: 0px 4px 13px rgba(230, 236, 244, 0.6);
            border-radius: 10px;
            &:nth-of-type(3n) {
              margin-right: 0;
            }
            .item-pic {
              width: toPad(80);
              height: toPad(80);
              border-radius: toPad(40);
              margin-right: toPad(20);
            }

            .item-text {
              width: toPad(280);
              .text-one {
                display: flex;
                align-items: center;
                font-weight: 500;
                font-size: toPad(18);
                line-height: toPad(24);
                margin-bottom: toPad(8);
                .name-text {
                  display: inline-block;
                  max-width: 72%;
                }
                .lable {
                  display: inline-flex;
                  color: #fff;
                  border-radius: toPad(5) 0px;
                  background: linear-gradient(
                    90.61deg,
                    #60cfc6 3.65%,
                    #61bfb4 99.54%
                  );
                  padding: 0 toPad(4);
                  text-align: center;
                  margin-left: toPad(6);
                  font-style: normal;
                  border: 1px solid rgba(152, 244, 235, 0.6);
                  line-height: toPad(16);

                  span {
                    transform: scale(0.9);
                    font-size: toPad(12);
                  }
                }
              }

              .text-two {
                margin-bottom: toPad(12);

                .tag {
                  padding: 0px toPad(5);
                  height: toPad(18);
                  line-height: toPad(18);
                  margin-right: 4px;
                  background: rgba(0, 0, 0, 0.05);
                }
              }

              .text-thr {
                margin-bottom: toPad(2);
                .text-item {
                  display: flex;
                  width: 100%;
                  font-size: toPad(14);
                  color: rgba(0, 0, 0, 0.5);

                  em {
                    font-style: normal;
                    flex-shrink: 0;
                  }
                  > span {
                    display: inline-block;
                    flex-basis: 100%;
                    padding-left: toPad(16);
                    font-size: toPad(16);
                    color: #000;
                    overflow: hidden;

                    span {
                      display: inline-block;
                      width: 100%;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    }
                  }
                }
              }
            }
          }
        }

        .user-empty {
          margin: toPad(200) auto;
          text-align: center;

          img {
            width: toPad(164);
            height: toPad(142);
          }
        }
        .end-wrap {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: toPad(50) auto toPad(50);
          span {
            display: inline-block;
            &.line {
              width: toPad(66);
              height: toPad(1);
              background: rgba(0, 0, 0, 0.21);
            }
            &.txt {
              margin: 0 toPad(20);
              color: rgba(0, 0, 0, 0.3);
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1601px) {
  .member-list {
    overflow: hidden;
    .header-bg {
      background: #fff;
    }

    .member-list-content {
      padding: toPad(15) 0 toPad(20);

      .tab-wrap {
        display: flex;
        align-items: center;
        padding-left: toPad(40);
        .tab-item {
          height: toPad(45);
          padding: 0 toPad(16);
          border-radius: toPad(6);
          margin-right: toPad(10);

          .icon-pic {
            width: toPad(16);
            height: toPad(16);
          }

          &.active {
            background: rgba(109, 182, 147, 0.17);
          }
        }
        .split-line {
          width: 1px;
          height: toPad(20);
          margin-right: toPad(10);
          background: rgba(0, 0, 0, 0.3);
        }
      }
      .member-block {
        overflow: auto;
        height: 77vh;
        padding: 0 toPad(40);
        .member-title {
          line-height: toPad(62);
          //margin: toPad(14) 0;
        }

        .member-wrap {
          display: flex;
          flex-wrap: wrap;
          //min-height: 80vh;
          .member-item {
            display: flex;
            width: toPad(363);
            height: toPad(134);
            padding: toPad(15);
            margin: toPad(10) toPad(6) 0 0;
            background: #fff;
            box-shadow: 0px 4px 13px rgba(230, 236, 244, 0.6);
            border-radius: 10px;
            &:nth-of-type(4n) {
              margin-right: 0;
            }
            .item-pic {
              width: toPad(80);
              height: toPad(80);
              border-radius: toPad(40);
              margin-right: toPad(20);
            }

            .item-text {
              width: toPad(230);
              .text-one {
                display: flex;
                align-items: center;
                font-weight: 500;
                font-size: toPad(18);
                line-height: toPad(24);
                margin-bottom: toPad(8);
                .name-text {
                  display: inline-block;
                  max-width: 72%;
                }
                .lable {
                  display: inline-flex;
                  color: #fff;
                  border-radius: toPad(5) 0px;
                  background: linear-gradient(
                    90.61deg,
                    #60cfc6 3.65%,
                    #61bfb4 99.54%
                  );
                  padding: 0 toPad(4);
                  text-align: center;
                  margin-left: toPad(6);
                  font-style: normal;
                  border: 1px solid rgba(152, 244, 235, 0.6);
                  line-height: toPad(16);

                  span {
                    transform: scale(0.9);
                    font-size: toPad(12);
                  }
                }
              }

              .text-two {
                margin-bottom: toPad(12);

                .tag {
                  padding: 0px toPad(5);
                  height: toPad(18);
                  line-height: toPad(18);
                  margin-right: 4px;
                  background: rgba(0, 0, 0, 0.05);
                }
              }

              .text-thr {
                margin-bottom: toPad(2);
                .text-item {
                  display: flex;
                  width: 100%;
                  font-size: toPad(14);
                  color: rgba(0, 0, 0, 0.5);

                  em {
                    font-style: normal;
                    flex-shrink: 0;
                  }
                  > span {
                    display: inline-block;
                    flex-basis: 100%;
                    padding-left: toPad(16);
                    font-size: toPad(16);
                    color: #000;
                    overflow: hidden;

                    span {
                      display: inline-block;
                      width: 100%;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    }
                  }
                }
              }
            }
          }
        }

        .user-empty {
          margin: toPad(200) auto;
          text-align: center;

          img {
            width: toPad(164);
            height: toPad(142);
          }
        }
        .end-wrap {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: toPad(50) auto toPad(50);
          span {
            display: inline-block;
            &.line {
              width: toPad(66);
              height: toPad(1);
              background: rgba(0, 0, 0, 0.5);
            }
            &.txt {
              margin: 0 toPad(20);
              color: rgba(0, 0, 0, 0.5);
            }
          }
        }
      }
    }
  }
}
</style>
